import React from 'react';
import loadable from '@loadable/component';
import { graphql, PageProps } from 'gatsby';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import {
  ServicesStyled,
  ServiceSectionAltStyled,
  ServiceSectionStyled,
} from '@/components/styled/ServicesStyled';
import { SectionProp } from '@/types';
import { usePageMeta } from '@/components/app/hooks';
import Reveal from '@/components/reveal/Reveal';

import techIcon from '@/../static/assets/images/tech-icon.png';
import strategyIcon from '@/../static/assets/images/strategy-icon.png';
import experienceIcon from '@/../static/assets/images/experience-icon.png';

import strategyImgIcon from '@/../static/assets/images/services/strategy-img.jpg';
import experienceImgIcon from '@/../static/assets/images/services/experience-img.jpg';
import technologyImgIcon from '@/../static/assets/images/services/technology-img.jpg';

import commerceToolsImg from '@/../static/assets/images/services/platforms/commercetools.png';
import contentfulImg from '@/../static/assets/images/services/platforms/contentful.png';
import bigcommerceImg from '@/../static/assets/images/services/platforms/bigcommerce.png';
import shopifyImg from '@/../static/assets/images/services/platforms/shopify.png';
import centraImg from '@/../static/assets/images/services/platforms/centra.png';
import awsImg from '@/../static/assets/images/services/platforms/aws.png';
import bluestoneImg from '@/../static/assets/images/services/platforms/bluestone-pim.png';
import salesLayerImg from '@/../static/assets/images/services/platforms/sales-layer.png';
import vueStorefrontImg from '@/../static/assets/images/services/platforms/vue-storefront.png';
import talonOneImg from '@/../static/assets/images/services/platforms/talon-one.png';
import dynamicYieldImg from '@/../static/assets/images/services/platforms/dynamic-yield.png';
import googleCloudImg from '@/../static/assets/images/services/platforms/gcp.png';
import sanityImg from '@/../static/assets/images/services/platforms/sanity.png';
import storyblokImg from '@/../static/assets/images/services/platforms/storyblok.png';
import vercelImg from '@/../static/assets/images/services/platforms/vercel.png';

import jensImg from '@/../static/assets/images/staff/jens.jpg';
import lauriImg from '@/../static/assets/images/staff/lauri.jpg';
import mutteImg from '@/../static/assets/images/staff/mutte.jpg';

const SpotlightSection = loadable(
  () => import(`@/app/spotlight/SpotlightSection`),
);

interface ServiceProps extends PageProps {
  data: {
    contentfulBlock: SectionProp;
  };
}

const services: React.FC<ServiceProps> = ({ data }) => {
  const servicesPageMeta = data.contentfulBlock?.meta ?? {};

  return (
    <ServicesStyled>
      {usePageMeta(servicesPageMeta)}
      <div className="content services-page container">
        <Reveal className="page-title h1-title">
          <span className="heading-title">Services</span>
          <h1>
            Your partner in digital commerce, with you through every stage of
            your journey
          </h1>
        </Reveal>

        <Reveal className="what-we-do">
          <h2 className="sub-title">What we do</h2>

          <div className="services">
            <div className="service-card">
              <p>
                We help brands identify and harness high-value digital commerce
                opportunities as they emerge
              </p>
              <a href="#strategy">
                <div className="service-icon">
                  <img
                    src={strategyIcon}
                    width="40"
                    height="40"
                    alt="Strategy"
                  />
                </div>
                Strategy
              </a>
            </div>

            <div className="service-card">
              <p>
                We design meaningful shopping experiences your customers will
                delight in
              </p>
              <a href="#experience">
                <div className="service-icon">
                  <img
                    src={experienceIcon}
                    width="40"
                    height="40"
                    alt="Experience"
                  />
                </div>
                Experience
              </a>
            </div>

            <div className="service-card">
              <p>
                We build composable and headless B2B and B2C commerce solutions
                that drive conversions and top-line growth
              </p>
              <a href="#technology">
                <div className="service-icon">
                  <img src={techIcon} width="40" height="40" alt="Technology" />
                </div>
                Technology
              </a>
            </div>
          </div>
        </Reveal>
      </div>

      <ServiceSectionAltStyled id="strategy">
        <div className="container">
          <div className="row">
            <Reveal className="col-2 col-m-2">
              <h3>
                <img src={strategyIcon} width="40" height="40" alt="Strategy" />
                Strategy
              </h3>

              <p>
                Consumer needs are changing fast. We help brands identify, test
                and pioneer the latest eCommerce opportunities as they emerge.
              </p>
            </Reveal>

            <Reveal className="col-2 col-m-2">
              <img src={strategyImgIcon} alt="Strategy" />
            </Reveal>
          </div>
        </div>
      </ServiceSectionAltStyled>

      <ServiceSectionStyled>
        <div className="container">
          <div className="row">
            <Reveal className="col-2 col-m-2">
              <p className="service-paragraph">
                In today&lsquo;s environment, organisations can&lsquo;t afford
                to get stuck. Maintaining a competitive advantage requires quick
                reflexes and an ability to execute on incomplete information.
              </p>
              <p className="service-paragraph">
                Our iterative and integrated model empowers our clients to seize
                emerging opportunities quickly. We find the right balance
                between market potential and opportunity - inspiring new ideas
                that can be validated through quick prototypes and calculations.
                Our clients are the first to champion new frontiers.
              </p>

              <div className="service-quote desktop">
                <p>
                  “Working seamlessly as one team, IONA supported us in
                  designing a unique business model that harnessed artificial
                  intelligence, data, and drone technology to secure electricity
                  distribution in the future.”
                </p>

                <span className="name">Milla Ratia</span>
                <span className="staff-info">
                  Chief Digital Officer at Suur-Savon Sähkö
                </span>
              </div>
            </Reveal>

            <Reveal className="col-2 col-m-2 flex-column">
              <Accordion className="service-accordion">
                <AccordionItem header="Strategy">
                  <ul>
                    <li>Product strategy</li>
                    <li>ICT and digital strategy</li>
                    <li>Channel strategy</li>
                    <li>ecommerce strategy</li>
                    <li>CX vision and roadmap</li>
                  </ul>
                </AccordionItem>

                <AccordionItem header="Business Design">
                  <ul>
                    <li>Operational model</li>
                    <li>Business model design</li>
                    <li>Commercialisation and productisation</li>
                    <li>New business development and innovation programs</li>
                    <li>Concept design</li>
                  </ul>
                </AccordionItem>

                <AccordionItem header="Implementation">
                  <ul>
                    <li>Piloting</li>
                    <li>Replatforming management</li>
                    <li>Product leadership</li>
                    <li>Process development</li>
                  </ul>
                </AccordionItem>
              </Accordion>

              <div className="service-quote mobile">
                <p>
                  “Working seamlessly as one team, IONA supported us in
                  designing a unique business model that harnessed artificial
                  intelligence, data, and drone technology to secure electricity
                  distribution in the future.”
                </p>

                <span className="name">Milla Ratia</span>
                <span className="staff-info">
                  Chief Digital Officer at Suur-Savon Sähkö
                </span>
              </div>

              <div className="get-in-touch">
                <h3>Get in touch</h3>
                <div className="get-in-touch-content">
                  <img
                    src={jensImg}
                    width="104"
                    height="104"
                    alt="Jens Mattsson"
                  />

                  <div className="staff-details">
                    <p className="name">Jens Mattsson</p>

                    <div className="staff-info">
                      <p className="position">Chief Business Officer</p>
                      <a href="mailto:jens@ionacommerce.com" className="email">
                        jens@ionacommerce.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Reveal>
          </div>
        </div>
      </ServiceSectionStyled>

      <ServiceSectionAltStyled id="experience">
        <div className="container">
          <div className="row">
            <Reveal className="col-2 col-m-2">
              <h3>
                <img
                  src={experienceIcon}
                  width="40"
                  height="40"
                  alt="Experience"
                />
                Experience
              </h3>

              <p>
                We craft memorable digital experiences that “wow” customers,
                boosting growth by selling more online.
              </p>
            </Reveal>

            <Reveal className="col-2 col-m-2">
              <img src={experienceImgIcon} alt="Experience" />
            </Reveal>
          </div>
        </div>
      </ServiceSectionAltStyled>

      <ServiceSectionStyled>
        <div className="container">
          <div className="row">
            <Reveal className="col-2 col-m-2">
              <p className="service-paragraph">
                Great digital experiences unite commercial goals and customer
                desires. We work collaboratively with our clients to curate
                meaningful customer experiences by mapping out the service and
                connecting essential touchpoints across the ecosystem.
              </p>
              <p className="service-paragraph">
                We start by understanding what makes your customers tick. Then
                we get to work designing experiences they&lsquo;ll love. Our
                interactive approach to designing and prototyping lets you
                explore the user journey end-to-end - putting you in the front
                seat of the consumer experience.
              </p>

              <div className="service-quote desktop">
                <p>
                  “6/5, the project exceeded my expectations. IONA didn&lsquo;t
                  only prototype an optimal digital purchase path but helped us
                  design the new product portfolio in one category and a new way
                  of selling.”
                </p>

                <span className="name">Jouni Petrow</span>
                <span className="staff-info">
                  Director Customer Experience at Elisa
                </span>
              </div>
            </Reveal>

            <Reveal className="col-2 col-m-2 flex-column">
              <Accordion className="service-accordion">
                <AccordionItem header="Insight & foresight">
                  <ul>
                    <li>Qualitative customer research</li>
                    <li>Customer experience audit and benchmark</li>
                    <li>Conversion optimisation analysis</li>
                    <li>Digital commerce experience strategy</li>
                  </ul>
                </AccordionItem>

                <AccordionItem header="Product design">
                  <ul>
                    <li>Product strategy and vision</li>
                    <li>Digital product creation</li>
                    <li>Commercial concept design</li>
                    <li>Digital branding and style guide design</li>
                  </ul>
                </AccordionItem>

                <AccordionItem header="Digital commerce solutions">
                  <ul>
                    <li>Service design</li>
                    <li>Purchase path prototyping and user validation</li>
                    <li>ecommerce UX and UI design</li>
                  </ul>
                </AccordionItem>
              </Accordion>

              <div className="service-quote mobile">
                <p>
                  “6/5, the project exceeded my expectations. IONA didn&lsquo;t
                  only prototype an optimal digital purchase path but helped us
                  design the new product portfolio in one category and a new way
                  of selling.”
                </p>

                <span className="name">Jouni Petrow</span>
                <span className="staff-info">
                  Director Customer Experience at Elisa
                </span>
              </div>

              <div className="get-in-touch">
                <h3>Get in touch</h3>
                <div className="get-in-touch-content">
                  <img
                    src={lauriImg}
                    width="104"
                    height="104"
                    alt="Jan-Eric Wargelin"
                  />

                  <div className="staff-details">
                    <p className="name">Lauri Virkkunen</p>

                    <div className="staff-info">
                      <p className="position">Design Director</p>
                      <a href="mailto:lauri@ionacommerce.com" className="email">
                        lauri@ionacommerce.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Reveal>
          </div>
        </div>
      </ServiceSectionStyled>

      <ServiceSectionAltStyled id="technology">
        <div className="container">
          <div className="row">
            <Reveal className="col-2 col-m-2">
              <h3>
                <img src={techIcon} width="40" height="40" alt="Technology" />
                Technology
              </h3>

              <p>
                Partnered with the world&lsquo;s leading composable and headless
                SaaS vendors in digital commerce, we offer an unbeatable tech
                stack across different industries and use cases.
              </p>
            </Reveal>

            <Reveal className="col-2 col-m-2">
              <img src={technologyImgIcon} alt="Technology" />
            </Reveal>
          </div>
        </div>
      </ServiceSectionAltStyled>

      <ServiceSectionStyled>
        <div className="container">
          <div className="row">
            <Reveal className="col-2 col-m-2">
              <p className="service-paragraph">
                The journey begins with a discovery process, where our eCommerce
                experts aim to envision your future digital landscape through a
                series of collaborative workshops. We design and build eCommerce
                experiences that are fast, compliant and accessible. Favouring
                the MACH approach (Microservices, API-first, Cloud-Native,
                Headless) - known as the superior approach to digital commerce
                development - our eCommerce experts help you select the right
                integrations, personalisation, loyalty and SEO executions for
                your brand.
              </p>
            </Reveal>

            <Reveal className="col-2 col-m-2">
              <Accordion className="service-accordion">
                <AccordionItem header="Technical consultancy">
                  <ul>
                    <li>Current state assessment</li>
                    <li>Requirements gathering</li>
                    <li>Platform selection</li>
                    <li>Analytics setup</li>
                    <li>Product information management</li>
                  </ul>
                </AccordionItem>

                <AccordionItem header="Software development">
                  <ul>
                    <li>Cloud architecture</li>
                    <li>Composable commerce development</li>
                    <li>Integration scenarios</li>
                    <li>Front-end development</li>
                    <li>Integrations development</li>
                    <li>DevOps</li>
                    <li>Mobile apps</li>
                    <li>QA</li>
                  </ul>
                </AccordionItem>

                <AccordionItem header="Maintenance and optimisation">
                  <ul>
                    <li>Conversion optimisation</li>
                    <li>Monitoring</li>
                    <li>Issue resolution</li>
                    <li>Integration support</li>
                  </ul>
                </AccordionItem>
              </Accordion>
            </Reveal>
          </div>
        </div>

        <Reveal className="container">
          <SpotlightSection shouldShowHeader={false} />
        </Reveal>

        <div className="platforms">
          <Reveal className="container">
            <h3>Platforms we use</h3>

            <div className="platform-img-container">
              <div className="platform-img">
                <span>Premier Partner</span>
                <img
                  src={commerceToolsImg}
                  width="205"
                  height="94"
                  alt="Commercetools"
                />
              </div>
              <div className="platform-img">
                <span>Bronze Partner</span>
                <img
                  src={contentfulImg}
                  width="205"
                  height="94"
                  alt="Contentful"
                />
              </div>
              <div className="platform-img">
                <span>Preferred Partner</span>
                <img src={sanityImg} width="205" height="94" alt="Sanity" />
              </div>
              <div className="platform-img">
                <img
                  src={bigcommerceImg}
                  width="205"
                  height="94"
                  alt="Bigcommerce"
                />
              </div>
              <div className="platform-img">
                <img src={shopifyImg} width="205" height="94" alt="Shopify" />
              </div>
              <div className="platform-img">
                <img src={centraImg} width="205" height="94" alt="Centra" />
              </div>
              <div className="platform-img">
                <img
                  src={googleCloudImg}
                  width="205"
                  height="94"
                  alt="Goole Cloud"
                />
              </div>
              <div className="platform-img">
                <img src={vercelImg} width="205" height="94" alt="Vercel" />
              </div>
              <div className="platform-img">
                <img src={awsImg} width="205" height="94" alt="AWS" />
              </div>
              <div className="platform-img">
                <img
                  src={bluestoneImg}
                  width="205"
                  height="94"
                  alt="Bluestone"
                />
              </div>
              <div className="platform-img">
                <img
                  src={salesLayerImg}
                  width="205"
                  height="94"
                  alt="Sales Layer"
                />
              </div>
              <div className="platform-img">
                <img
                  src={storyblokImg}
                  width="205"
                  height="94"
                  alt="Storyblok"
                />
              </div>
              <div className="platform-img">
                <img
                  src={talonOneImg}
                  width="205"
                  height="94"
                  alt="Talon One"
                />
              </div>
              <div className="platform-img">
                <img
                  src={vueStorefrontImg}
                  width="205"
                  height="94"
                  alt="Vue Storefront"
                />
              </div>
              <div className="platform-img">
                <img
                  src={dynamicYieldImg}
                  width="205"
                  height="94"
                  alt="Dynamic yield by Mastercard"
                />
              </div>
            </div>
          </Reveal>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-2 col-m-2">
              <div className="service-quote">
                <p>
                  “Iona&lsquo;s guidance and support kicking off our platform
                  review and subsequent implementation plan was invaluable and
                  an enjoyable process. Also, the migration coupled with the
                  improvements to the UX will be a great base for future
                  iteration.”
                </p>

                <span className="name">Simon Lamb</span>
                <span className="staff-info">Head of Technology at Alamy</span>
              </div>
            </div>

            <div className="col-2 col-m-2 flex-column">
              <div className="get-in-touch">
                <h3>Get in touch</h3>
                <div className="get-in-touch-content">
                  <img
                    src={mutteImg}
                    width="104"
                    height="104"
                    alt="Mikko Mantila"
                  />

                  <div className="staff-details">
                    <p className="name">Mikko Mantila</p>

                    <div className="staff-info">
                      <p className="position">Chief Executive Officer</p>
                      <a href="mailto:mikko@ionacommerce.com" className="email">
                        mikko@ionacommerce.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ServiceSectionStyled>
    </ServicesStyled>
  );
};

export const query = graphql`
  query Sections {
    contentfulBlock(section: { eq: "services_heading" }) {
      slug
      title
      meta {
        title
        description
        type
        image {
          file {
            url
          }
        }
      }
    }
  }
`;

export default services;
